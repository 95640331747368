import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { take, tap, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthInterface } from '../interfaces/auth.operations.interface';
import { UserInterface } from '../interfaces/user';
import { User } from '../model/user';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    public router: Router
  ) {}

  login(accessKey: string): any | Observable<any> {
    let headers = new HttpHeaders({
      // 'Authorization': 'Basic ' + btoa(mail + ':' + password),
      skip: 'true',
    });

    return this.http
      .post<string>(
        environment.register_api + 'open/register/auth',
        accessKey,
        { headers, responseType: 'text' as 'json' }
      )
      .subscribe({
        next: (resData) => this.handleAuthentication(resData),
        error: (err) => this.handleAuthenticationError(err),
      });
  }

  private handleAuthenticationError(err: any) {
    this.router.navigate(['/error/not-authorized']);
    return throwError(err);
  }

  private handleAuthentication(accessToken: string) {
    const auth = atob(accessToken);

    sessionStorage.setItem('auth', auth);
    localStorage.clear();
    this.router.navigate(['/']);
  }

  isAuthenticated(): boolean {
    return !!sessionStorage.getItem('auth');
  }

  logout() {
    sessionStorage.removeItem('auth');
  }

  getAuth(): AuthInterface {
    const accessToken = JSON.parse(sessionStorage.getItem('auth')!);
    return accessToken;
  }
}
