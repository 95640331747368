export class DocumentsTypeFinancialList {
  static list() {
    return [
      { name: 'Aditivo', value: 'ADDITIVE' },
      { name: 'Duplicata', value: 'TRADE_NOTE' },
      { name: 'Endosso', value: 'ENDORSEMENT' },
      { name: 'Carta de Sessão', value: 'SESSION_LETTER' },
      { name: 'Nota promissória', value: 'PROMISSORY_NOTE' },
      { name: 'Contrato mãe', value: 'MASTER_CONTRACT' },
    ];
  }
}