import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { take, exhaustMap, catchError, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserInterface } from '../interfaces/user';
import { AuthService } from '../services/auth.service';
import { buyerDashboardService } from '../services/buyer-dashboard.service';
import { NotifierService } from '../services/notifier.service';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptorService implements HttpInterceptor {
  constructor(
    private buyerDashboardService: buyerDashboardService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private notifierService: NotifierService
  ) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> | any {
    let auth = this.authService.getAuth();

    const skipIntercept = req.headers.has('skip');
    if (req.url.includes(environment.register_api)) {
      return next.handle(req);
    }

    if (skipIntercept) {
      const modifiedReq = req.clone({
        headers: req.headers.delete('skip'),
      });
      return next.handle(modifiedReq);
    }
    let modifiedReq = req.clone({
      setHeaders: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + auth.code,
      },
    });
    if (req.clone().url.includes('management-api/documents/leads?path=')) {
      modifiedReq = req.clone({
        setHeaders: {
          'Content-Type': 'application/json',
          Authorization:
            'Bearer ' +
            'v{pc5.61&_a&+lZzZMf]CoG#}H1(c]y#KL6X8gP-X4)=d_%dQ/=h|Lk=OHZoJ#W',
        },
      });
    }

    return next.handle(modifiedReq).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${error.error.message}`;
        } else {
          if (error.error.message === 'INVALID_TOKEN') {
            this.router.navigate(['/not-authorized']);
          }
          const message = error.error.customMessage ?? error.error.message;
          this.notifierService.showNotification({
            message: 'Erro ao salvar/exibir as alterações.',
            messageType: 'errorNotifier',
            alternative: 'Contate o time de operações!',
          });
          // server-side error
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        return throwError(errorMessage);
      })
    );
  }
}
