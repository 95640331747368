<mat-toolbar>
  <button mat-icon-button style="font-size: 18px;" (click)="navigateTo('../')">
    <mat-icon>arrow_back_ios</mat-icon>
  </button>
  <div class="d-flex flex-column">
    <span class="h5 mb-0">Operação</span>
    <small class="text-muted h6 mb-0" >Dados de operação</small>
  </div>
</mat-toolbar>

<section class="container-fluid border  bg-white p-4 my-4 width-95">
  <div class="row mb-2">
    <div class="col">
      <small><b>Cedente: </b></small>
      <span>{{ selectedItem.nameLead | uppercase}}</span>
    </div>
    <div class="col">
      <small><b>Sacado: </b></small>
      <span>{{ selectedItem.nameSacado | uppercase}}</span>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <small><b>Fator de deságio: </b></small>
      <span>{{ getPercentInfo(selectedItem.fee ) | percent : "1.0-2" }}</span>
    </div>
    <div class="col">
      <small><b>Vencimento: </b></small>
      <span>{{selectedItem.dueDate}}</span>
    </div>
    <div class="col">
      <small><b>Valor de Face: </b></small>
      <span>{{ selectedItem.totalAmount | currency: 'BRL' }}</span>
    </div>
    <div class="col">
      <small><b>Nº do Título: </b></small>
      <span>{{ selectedItem.numberInvoice }}</span>
    </div>
  </div>
</section>
<section class="container-fluid width-95 bg-white border p-4">
  <mat-tab-group >
    <mat-tab label="Dados do Cedente">
      <ng-template mat-tab-label>
        <label> Dados do Cedente</label>
      </ng-template>
      <ng-template matTabContent>
        <app-cedente-data></app-cedente-data>
      </ng-template>
    </mat-tab>
    <!-- <mat-tab label="Dados do Sacado">
      <div class="text-muted d-flex align-item-center">
        <b>Em desenvolvimento ...</b>
      </div>
    </mat-tab>
    <mat-tab label="Meu Resumo">
      <div class="text-muted d-flex align-item-center">
        <b>Em desenvolvimento ...</b>
      </div>
    </mat-tab> -->
  </mat-tab-group>
</section>

