import { Component, Inject } from '@angular/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from '@angular/material/snack-bar';
import { NotifierInterface } from 'src/app/interfaces/notifier.interface';

@Component({
  selector: 'app-assignor-snackbar',
  templateUrl: './assignor-snackbar.component.html',
  styleUrls: ['./assignor-snackbar.component.scss'],
})
export class AssignorSnackbarComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA)
    public data: NotifierInterface,
    public snackBarRef: MatSnackBarRef<AssignorSnackbarComponent>
  ) {}
}
