export const environment = {
  production: false,

  operation_api: 'http://api.dev.operations.broadfactor.com/',
  API_url: 'http://api.dev.antecipagov.com.br/',
  aditive_api: 'http://api.dev.signer.broadfactor.com/api/',
  register_api: 'http://api.register.dev.broadfactor.com/',
  signer_url: 'http://cloud.bry.com.br/scad/workflow/',
  financial_url: 'http://finance.dev.broadfactor.com/',
  operation_api_token:
    'Cj$rJwr23ZjVYb7z3AfjY6%u&w^@M^WvwHWw@^m#Y4pwgB3Z7jfZ6m5sKZaO!LAfi7!BYyT$MBYx%YrhLRoe7Xag9ojjVKclLtFrR1jE@eqz&#5vVHhwoYQ0IrJHL1%y%nILjYuuE#YV9&mUe2luG&vYZZ%zYrE6jG*M4NQ&x6iB5&5CMpBdqd8H9tLw%VSP0xhu0r',
};
