import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import moment, { Moment } from 'moment';
import {
  Observable,
  Subject,
  catchError,
  map,
  of,
  take,
  takeUntil,
} from 'rxjs';
import {
  FinancialHistoryContent,
  LeadContent,
} from 'src/app/interfaces/financial.interface';
import { FinancialService } from 'src/app/services/financial.service';
import { NotifierService } from 'src/app/services/notifier.service';
import { CategoryList } from 'src/app/utils/category-list';
import { DocumentsTypeFinancialList } from 'src/app/utils/documents-type-financial-list';

@Component({
  selector: 'app-financial-detalhes',
  templateUrl: './financial-detalhes.component.html',
  styleUrls: ['./financial-detalhes.component.scss'],
})
export class FinancialDetalhesComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = [
    'cedente',
    'sacado',
    'face',
    'liquidado',
    'vencimento',
    'numero',
    'data',
    'documento',
    'aditivo',
    'valor',
    'categoria',
  ];
  pageIndex = 0;
  pageSize = 50;
  dataSource: FinancialHistoryContent[] = [];
  inputsFilter!: FormGroup;
  dateSelected!: Moment;
  company!: string;
  totalValue!: number;

  errorHandling = { errorNotFind: false, errorRequest: false };

  private unsubscribe = new Subject<void>();

  private debounceTimer?: NodeJS.Timeout;
  leadOptions: LeadContent[] = [];
  draweeOptions: any[] = [];
  categoryOptions = CategoryList.list();
  documentOptions = DocumentsTypeFinancialList.list();

  constructor(
    private financialService: FinancialService,
    private notifierService: NotifierService
  ) {}

  ngOnInit(): void {
    this.inputsFilter = new FormGroup({
      cedente: new FormControl('', { nonNullable: true }),
      sacado: new FormControl('', { nonNullable: true }),
      title: new FormControl('', { nonNullable: true }),
      additive: new FormControl('', { nonNullable: true }),
      category: new FormControl(
        { name: '', entryType: '', accountType: '', class: '' },
        { nonNullable: true }
      ),
    });

    this.dateSelected = this.financialService.getDateSelected();
    this.company = this.financialService.getCompanyName();
    this.getDataDetails().subscribe((e) => (this.dataSource = e));
    this.financialService.getTenantId();
    this.getTotal();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  createBodyFilter() {
    const filterObject = {
      date: `${this.dateSelected.format('DD/MM/YYYY')}`,
      tenantId: `${this.financialService.getTenantId()}`,
      leadId: `${this.inputsFilter.value.cedente?.id}`,
      draweeId: `${this.inputsFilter.value.sacado?.id}`,
      additive: `${this.inputsFilter.value.additive.trim()}`,
      installment: `${this.inputsFilter.value.title.trim()}`,
      category: `${this.inputsFilter.value.category.accountType}`,
      type: `${this.inputsFilter.value.category.entryType}`,
    };
    return filterObject;
  }

  getDataDetails(): Observable<FinancialHistoryContent[]> {
    const body = this.createBodyFilter();

    return this.financialService
      .getHistory(this.pageSize, this.pageIndex, body)
      .pipe(
        take(1),
        takeUntil(this.unsubscribe),
        map((e) => e.content),
        catchError((error) => {
          this.errorHandling.errorRequest = true;

          return of();
        })
      );
  }

  onFilter() {
    this.pageIndex = 0;
    this.dataSource = [];
    this.errorHandling = { errorNotFind: false, errorRequest: false };
    this.getDataDetails().subscribe((e) => {
      if (e.length === 0) this.errorHandling.errorNotFind = true;
      this.dataSource = e;
    });
    this.getTotal();
  }

  clearFilters() {
    this.inputsFilter.reset();
    this.errorHandling = { errorNotFind: false, errorRequest: false };
    this.dataSource = [];
    this.leadOptions = [];
    this.draweeOptions = [];

    this.pageIndex = 0;
    this.pageSize = 50;
    this.getDataDetails().subscribe((e) => (this.dataSource = e));
    this.getTotal();
  }

  dowloandPDF(id: string, nameArchive: string) {
    this.financialService.getDocumentDowloand().subscribe({
      next: (pdfBlob) => {
        const pdfSrc = 'assets/png/Vertical.png'; //URL.createObjectURL(pdfBlob);
        const link = document.createElement('a');
        link.href = pdfSrc;
        link.download = 'Vertical-name.png';
        link.click();
      },

      error: (error) => {
        this.errorHandling.errorNotFind = true;
      },
    });
  }

  onTableScroll() {
    this.pageIndex++;
    this.getDataDetails().subscribe((items) => {
      this.dataSource = [...this.dataSource, ...items];
    });
  }

  onInput(value: string = '', type: string) {
    if (value.length <= 3) return;
    if (this.debounceTimer) clearTimeout(this.debounceTimer);

    this.debounceTimer = setTimeout(() => {
      this.financialService
        .getDataLeads(value, type)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((actor) => {
          type == 'LEAD'
            ? (this.leadOptions = actor.content)
            : (this.draweeOptions = actor.content);
        });
    }, 500);
  }

  displayDrawee(element: any) {
    if (!element.id) return '';

    let index = this.draweeOptions.findIndex(
      (option) => option.id === element.id
    );
    return this.draweeOptions[index].name;
  }

  displayLead(element: any) {
    if (!element.id) return '';

    let index = this.leadOptions.findIndex(
      (option) => option.id === element.id
    );
    return this.leadOptions[index].name;
  }

  getTotal() {
    const body = this.createBodyFilter();
    this.financialService
      .getTotalDatails(body)
      .pipe(take(1), takeUntil(this.unsubscribe))
      .subscribe((value) => {
        this.totalValue = value;
      });
  }

  getCategoryName(element: any) {
    return (
      this.categoryOptions.find(
        (e) =>
          e.accountType === element.accountType &&
          e.entryType === element.entryType
      )?.name ?? 'N/A'
    );
  }

  getDocumentName(document: string) {
    return this.documentOptions.find((e) => e.value === document)?.name ?? '';
  }

  getClassCategory(element: any) {
    return (
      this.categoryOptions.find(
        (e) =>
          e.accountType === element.accountType &&
          e.entryType === element.entryType
      )?.class ?? ''
    );
  }

  validationInpuLead() {
    if (this.inputsFilter.value.cedente?.id) return;
    this.inputsFilter.get('cedente')?.setValue('');
  }

  validationInputSacado() {
    if (this.inputsFilter.value.sacado?.id) return;
    this.inputsFilter.get('sacado')?.setValue('');
  }
}
