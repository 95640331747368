import { Component, Input, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { OperationLead } from 'src/app/interfaces/operacaoFilters';
import { buyerDashboardService } from 'src/app/services/buyer-dashboard.service';
import { Output, EventEmitter } from '@angular/core';
import { FiltersInterface } from 'src/app/interfaces/filters.interface';
import { debounceTime, of, switchMap, take } from 'rxjs';
import { maskCnpj } from 'src/app/utils/string-to-cnpj-mask';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent {
  formFilters!: FormGroup;
  @Output() filterEvent = new EventEmitter<FiltersInterface>();
  @Input() tabStatus!: string | null;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(private buyerDashboardService: buyerDashboardService) {
    this.formFilters = new FormGroup({
      nameActorOne: new FormControl(null, []),
      numberInvoice: new FormControl(null),
      dueDateInit: new FormControl(null),
      dueDateEnd: new FormControl(null),
    });

    this.formFilters.controls['nameActorOne'].valueChanges
      .pipe(
        debounceTime(500),
        switchMap((data: string): any => {
          if (
            data &&
            data?.trimStart() !== '' &&
            data?.trimEnd() !== '' &&
            data?.length > 2
          ) {
            this.getSearchFomento('ACTOR_ONE', data);
          }

          return of('');
        })
      )
      .subscribe();
  }
  getSearchFomento(field: 'ACTOR_ONE' | 'ACTOR_TWO' | 'ALL', filter?: string) {
    let type = this.formFilters.get('typeActorOne')?.value;
    if (field === 'ACTOR_ONE') {
      type = this.formFilters.get('typeActorOne')?.value;
    }

    this.buyerDashboardService
      .getSearchFomento(type, filter)
      .pipe(take(1))
      .subscribe((res) => {
        if (field === 'ACTOR_ONE') {
          this.actorClientOptions = {
            ...this.actorClientOptions,
            actorOne: res.content,
          };
        }
      });
  }
  getFormatedForm() {
    const data = this.formFilters.value;

    if (data['dueDateInit'] !== null) {
      data['dueDateInit'] = new Date(data['dueDateInit'])
        .toISOString()
        .split('T')[0];
    }
    if (data['dueDateEnd'] !== null) {
      data['dueDateEnd'] = new Date(data['dueDateEnd'])
        .toISOString()
        .split('T')[0];
    }

    data['companyList'] = [
      {
        cnpj: data.nameActorOne ?? null,
        type: 'LEAD',
      },
    ];
    delete data['nameActorOne'];
    delete data['typeActorOne'];
    delete data['nameActorTwo'];
    delete data['typeActorTwo'];
    return data;
  }

  actorClientOptions: Record<
    string,
    { name: string; cnpj: string; id: string }[]
  > = {
    actorOne: [],
  };
  displayActorOne(cnpj?: string) {
    if (cnpj) {
      const { name } = this.actorClientOptions['actorOne'].find(
        (_: any) => _.cnpj === cnpj
      )!;

      return `${name} - ${maskCnpj(cnpj)}`;
    }

    return '';
  }

  formatedCnpjLabel(cnpj: string) {
    return maskCnpj(cnpj);
  }

  clearFilters() {
    this.formFilters.reset({ status: this.tabStatus });
    this.onSubmit();
  }
  onSubmit() {
    const data = this.getFormatedForm();

    this.filterEvent.emit(data);
  }
}
