<mat-drawer-container class="example-container">
  <mat-drawer #drawer class="example-sidenav" mode="side" opened>
    <img
      class="logo"
      src="../assets/svg/Broadfactor Logo - Colorido [Letra preta].svg"
      alt="logo"
    />
    <div
      class="button-container"
      [ngClass]="{ 'button-container-active': isRouterActive('operacoes') || isRouterActive('operacoes/dados-operacionais') }"
    >
      <button
        mat-button
        (click)="navigateTo('operacoes')"
        class="button-operations"
      >
        <mat-icon>shopping_cart</mat-icon>Operações
      </button>
    </div>
    <!-- <div [ngClass]="{'button-container-active': isRouterActive('/financeiro') || isRouterActive('/financeiro/detalhes') }" >
      <button
        mat-button
        (click)="navigateTo('/financeiro')"
        class="button-operations"
      >
        <mat-icon>attach_money</mat-icon>Financeiro
      </button>
    </div>  -->
  </mat-drawer>

  <router-outlet></router-outlet>
</mat-drawer-container>
