<div class="main-container">
  <div class="table-header-container">
    <span class="example-spacer"></span>
  </div>
  <app-filters
    [tabStatus]="customerStatus"
    (filterEvent)="handleSubmit($event)"
  ></app-filters>
  <section
    class="table-container mat-elevation-z8"
    tabindex="0"
    infiniteScroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="50"
    (scrolled)="getCustomers()"
    [scrollWindow]="false"
  >
    <table mat-table [dataSource]="leads">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox disabled></mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)"
          >
          </mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="confirmationDrawee">
        <th mat-header-cell *matHeaderCellDef>
          <button
            class="table-button"
            mat-icon-button
            matTooltip="Nota com confirmação do sacado."
          >
            <mat-icon>task</mat-icon>
          </button>
        </th>
        <td mat-cell *matCellDef="let row">
          <button
            class="table-button"
            mat-icon-button
            *ngIf="row.source === sourceOptions.BASE_B"
            matTooltip="Nota com confirmação do sacado."
          >
            <mat-icon>task</mat-icon>
          </button>
        </td>
      </ng-container>
      <ng-container matColumnDef="Cedente">
        <th mat-header-cell *matHeaderCellDef>Cedente</th>
        <td mat-cell *matCellDef="let element">
          {{ element.nameLead ?? "N/A" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="Sacado">
        <th mat-header-cell *matHeaderCellDef>Sacado</th>
        <td mat-cell *matCellDef="let element">
          {{ element.nameSacado ?? "N/A" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="Broker">
        <th mat-header-cell *matHeaderCellDef>Broker</th>
        <td mat-cell *matCellDef="let element">
          {{ element.nameBroker ?? "N/A" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="TipoOperacao">
        <th mat-header-cell *matHeaderCellDef>Tipo operação</th>
        <td mat-cell *matCellDef="let element">
          {{ getNameTypeOperation(element.operationType) ?? "N/A" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Parcela">
        <th mat-header-cell *matHeaderCellDef>Parcela</th>
        <td mat-cell *matCellDef="let element">
          {{ element.numberInstallment ?? "N/A" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Vencimento">
        <th mat-header-cell *matHeaderCellDef>Vencimento</th>
        <td mat-cell *matCellDef="let element">
          {{ element.dueDate ?? "N/A" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Tipo">
        <th mat-header-cell *matHeaderCellDef>Tipo</th>
        <td mat-cell *matCellDef="let element">
          {{ element.type ?? "N/A" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="DataOperacao">
        <th mat-header-cell *matHeaderCellDef>Data de Operação</th>
        <td mat-cell *matCellDef="let element">
          {{ element.buyDate ?? "N/A" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Numero">
        <th mat-header-cell *matHeaderCellDef>Número</th>
        <td mat-cell *matCellDef="let element">
          {{ element.numberInvoice ?? "N/A" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="responsável">
        <th mat-header-cell *matHeaderCellDef>Responsável</th>
        <td mat-cell *matCellDef="let element">
          {{ element.responsible ?? "N/A" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Valor de face">
        <th mat-header-cell *matHeaderCellDef>Valor de face</th>
        <td mat-cell *matCellDef="let element">
          {{ (element.totalAmount | currency : "BRL") ?? "N/A" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Valor em Aberto">
        <th mat-header-cell *matHeaderCellDef>Valor em Aberto</th>
        <td mat-cell *matCellDef="let element">
          {{ (element.remainingValue | currency : "BRL") ?? "N/A" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Valor líquido">
        <th mat-header-cell *matHeaderCellDef>Valor líquido</th>
        <td mat-cell *matCellDef="let element">
          {{ (element.amountToPay | currency : "BRL") ?? "N/A" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Aditivo">
        <th mat-header-cell *matHeaderCellDef>Aditivo</th>
        <td mat-cell *matCellDef="let element">
          <p class="link" (click)="openDialogAditive(element.additive)">
            {{ element.additive ?? "N/A" }}
          </p>
        </td>
      </ng-container>
      <ng-container matColumnDef="Observacao">
        <th mat-header-cell *matHeaderCellDef>Observação</th>
        <td mat-cell *matCellDef="let element">
          {{ element.obs ?? "N/A" }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        (click)="handlePressRow(row)"
        class="element-row"
        [class.expanded-row]="currentExpandedItem === row"
        [class.disabled]="
          selection.selected.length && selection.selected[0].id !== row.id
        "
        [matTooltipPosition]="'left'"
        matTooltip="Título está em análise pelo time de operação devido à solicitação de desistência da antecipação."
        [matTooltipDisabled]="!row.hasPending"
        [ngClass]="{ 'table-text-warning': row.hasPending }"
      ></tr>
    </table>
    <div class="spinner-container" *ngIf="isLoading">
      <mat-spinner class="spinner" color="accent"></mat-spinner>
    </div>
    <div class="container-blankslate" *ngIf="!isLoading && leads.length == 0">
      <p>Nenhum dado encontrado com os filtros utilizados.</p>
    </div>
  </section>

  <div>
    <div class="actions-container">
      <button
        mat-flat-button
        color="accent"
        class="button-settlement"
        (click)="liquidate()"
        [disabled]="!selection.selected.length"
      >
        Liquidar valor de face
      </button>
      <button
        mat-flat-button
        color="accent"
        class="button-settlement"
        (click)="openDialogLiquidatedPartinal()"
        [disabled]="!selection.selected.length"
      >
        Liquidar valor
        <label
          matTooltip="Para liquidações parciais ou para liquidações com juros recebidos."
          ><mat-icon>help</mat-icon>
        </label>
      </button>
    </div>
  </div>

  <app-aside [opened]="!!currentExpandedItem" (isClose)="handleCloseSidenav()">
    <section header>
      <div class="flex-column">
        <span class="text-black"
          ><strong>{{ currentExpandedItem?.nameLead }}</strong></span
        >
        <span class="text-black">{{
          currentExpandedItem?.cnpjLead | cnpj
        }}</span>
      </div>

      <div class="flex-column">
        <span class="text-black"
          >Número do Título: {{ currentExpandedItem?.numberInvoice }}</span
        >
        <span class="text-black"
          >Taxa:
          {{
            getPercentInfo(currentExpandedItem?.fee!) | percent : "1.0-2"
          }}</span
        >
        <span class="text-black"
          >Valor de Face:
          {{ currentExpandedItem?.totalAmount | currency : "BRL" }}</span
        >
        <span class="text-black"
          >Valor Líquido:
          {{ currentExpandedItem?.amountToPay | currency : "BRL" }}</span
        >
      </div>

      <div class="badge-green">Em Cobrança</div>
    </section>

    <section content>
      <div class="flex-row">
        <button
          mat-stroked-button
          class="sidenav-btn width-50"
          (click)="openDialogRegisterCedente(currentExpandedItem!)"
        >
          <mat-icon>assignment</mat-icon>
          Dados cadastrais
        </button>
        <button
          mat-stroked-button
          class="sidenav-btn width-50"
          (click)="navigateTo('dados-operacionais')"
        >
          <mat-icon>insert_chart</mat-icon>
          Dados operacionais
        </button>
      </div>

      <button
        mat-stroked-button
        class="sidenav-btn width-100"
        (click)="
          getDownloadFilesOperation(
            currentExpandedItem!.id,
            currentExpandedItem!.numberInvoice!
          )
        "
      >
        <mat-icon>file_download</mat-icon>
        Baixar NF e documentos adicionais
      </button>
    </section>

    <div content>
      <div class="settlement-history">
        <div class="title-container">
          <p class="title">Histórico de liquidação</p>
        </div>
        <div class="label">
          <div class="table-column">
            <mat-icon>calendar_today</mat-icon>
            <p class="value-text">Liquidação</p>
          </div>
          <p class="value-text">Valor</p>
        </div>

        <div
          class="label text"
          *ngFor="let payment of currentExpandedItem?.paymentsHistory"
        >
          <span>{{ payment.date }}</span>
          <span>{{ payment.value | currency : "BRL" }}</span>
        </div>

        <div class="line"></div>

        <p class="value">{{ totalPayment | currency : "BRL" }}</p>

        <div class="label">
          <div class="table-column">
            <mat-icon class="icon-alert">report_problem</mat-icon>
            <p class="value-total">Total em Aberto</p>
          </div>
          <p class="value-open">
            {{ currentExpandedItem?.remainingValue | currency : "BRL" }}
          </p>
        </div>
      </div>
    </div>
  </app-aside>
</div>
