import { Component, Input } from '@angular/core';
import { ContactCompany } from 'src/app/interfaces/companyData.interface';
import { OperationLead } from 'src/app/interfaces/operacaoFilters';
import { OperationDataCedente } from 'src/app/interfaces/operation-data.interface';
import { buyerDashboardService } from 'src/app/services/buyer-dashboard.service';

@Component({
  selector: 'app-cedente-data',
  templateUrl: './cedente-data.component.html',
  styleUrls: ['./cedente-data.component.scss']
})
export class CedenteDataComponent {
  data!: OperationDataCedente;
  selectedItem!: OperationLead;
  contactList: ContactCompany[] = [];
  isLoading: boolean = false;
  hasError: boolean = false;

  constructor(private service: buyerDashboardService) {
    this.getSelectedItem();
    this.getOperationData();
    this.getContact();
  }

  getSelectedItem(){
    this.selectedItem = history.state.item;
  }

  getOperationData(){
    this.isLoading = true;
    this.service.getOperationDataCedente(this.selectedItem?.id).subscribe({
      next: (response) => {
        this.data = response;
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
        this.hasError = true;
      }
    });
  }

  getContact(){
    this.service.getContactCompany(this.selectedItem.leadId).subscribe({
      next: (response) => {
        this.contactList = response;
      }
    })
  }

  get progressBarWidth(): string {
    if (this.data && this.data.creditLimit) {
      const usedLimit = this.data.creditLimit.usedLimit || 0;
      const totalLimit = this.data.creditLimit.totalLimit || 1; // Evita divisão por zero
      const width = (usedLimit / totalLimit) * 100;
      return `${width}%`;
    }
    return '0%';
  }

  getPercentInfo(percent: string | number) {
    return +percent * 0.01;
  }
}
