<mat-tab-group #tabGroup color="accent" class="mat-tab">
  <mat-tab label="À venda">
    <ng-template mat-tab-label>
      <label
        class="labelHeading"
        matTooltip="NFs à venda para fomentos parceiras"
        matTooltipClass="example-tooltip-red1"
        ><mat-icon>shopping_cart</mat-icon> À venda
      </label>
    </ng-template>
    <ng-template matTabContent>
      <app-for-sale></app-for-sale>
    </ng-template>
  </mat-tab>
  <mat-tab label="Em análise">
    <ng-template mat-tab-label>
      <label
        class="labelHeading"
        matTooltip="Títulos selecionados pelas fomentos"
        matTooltipClass="example-tooltip-red1"
        ><mat-icon>search</mat-icon> Em análise
      </label>
    </ng-template>
    <ng-template matTabContent>
      <app-analysis-titles-view></app-analysis-titles-view>
    </ng-template>
  </mat-tab>

  <mat-tab label="Documentos">
    <ng-template mat-tab-label>
      <label
        class="labelHeading"
        matTooltip="Antecipações pendentes de assinatura e pagamento"
        matTooltipClass="example-tooltip-red1"
        ><mat-icon>border_color</mat-icon> Aguardando assinatura
      </label>
    </ng-template>
    <ng-template matTabContent>
      <app-documents-titles></app-documents-titles>
    </ng-template>
  </mat-tab>

  <mat-tab label="Pagos">
    <ng-template mat-tab-label>
      <label
        class="labelHeading"
        matTooltip="Títulos pagos pela fomento"
        matTooltipClass="example-tooltip-red1"
      >
        <mat-icon>verified</mat-icon> Pagos
      </label>
    </ng-template>
    <ng-template matTabContent>
      <app-paid></app-paid>
    </ng-template>
  </mat-tab>

  <mat-tab label="Liquidado">
    <ng-template mat-tab-label>
      <label
        class="labelHeading"
        matTooltip="Parcelas pagas pelo seu cliente"
        matTooltipClass="example-tooltip-red1"
      >
        <mat-icon>description</mat-icon> Liquidado
      </label>
    </ng-template>
    <ng-template matTabContent>
      <app-settled></app-settled>
    </ng-template>
  </mat-tab>

  <mat-tab label="Em cobrança">
    <ng-template mat-tab-label>
      <label
        class="labelHeading"
        matTooltip="Parcelas em atraso"
        matTooltipClass="example-tooltip-red1"
      >
        <mat-icon>attach_money</mat-icon> Em cobrança
      </label>
    </ng-template>
    <ng-template matTabContent>
      <app-in-collection></app-in-collection>
    </ng-template>
  </mat-tab>
</mat-tab-group>
