import { Component } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-home',
  templateUrl: './operations.component.html',
  styleUrls: ['./operations.component.scss'],
})
export class OperationsComponent {
  customerStatusOptions = {
    available: 'available',
    for_sale: 'for_sale',
    in_analysis: 'IN_ANALYSIS',
    documents: 'DOCUMENTS',
    paid: 'PAID',
    settled: 'SETTLED',
    in_collection: 'IN_COLLECTION',
  };
}
