import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OperationLead } from 'src/app/interfaces/operacaoFilters';
import { buyerDashboardService } from 'src/app/services/buyer-dashboard.service';

@Component({
  selector: 'app-operational-data',
  templateUrl: './operational-data.component.html',
  styleUrls: ['./operational-data.component.scss']
})
export class OperationalDataComponent {
  selectedItem!: OperationLead;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.selectedItem = history.state.item;
  }

  navigateTo(url: string){
    this.router.navigate([url], {relativeTo: this.route});
  }

  getPercentInfo(percent: string | number) {
    return +percent * 0.01;
  }

}
