import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { FinancialComponent } from './pages/financial/financial.component';
import { NotAuthorizedComponent } from './pages/not-authorized/not-authorized.component';
import { OperationsComponent } from './pages/operations/operations.component';
import { FinancialDetalhesComponent } from './components/financial-detalhes/financial-detalhes.component';
import { OperationalDataComponent } from './pages/operations/components/operational-data/operational-data.component';

const routes: Routes = [
  {
    path: '', redirectTo: 'operacoes', pathMatch: 'full'
  },
  {
    path: 'operacoes',
    component: OperationsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'operacoes/dados-operacionais',
    component: OperationalDataComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'financeiro',
    component: FinancialComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'financeiro/detalhes',
    component: FinancialDetalhesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: ':access_key',
    component: OperationsComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'error/not-authorized',
    component: NotAuthorizedComponent,
  },
  {
    path: '**',
    component: NotAuthorizedComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
