import { SelectionModel } from '@angular/cdk/collections';
import { CurrencyPipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import {
  MatTableDataSource,
  MatTableDataSourcePaginator,
} from '@angular/material/table';
import { take, map, finalize } from 'rxjs';
import { TitleListInterface } from 'src/app/interfaces/analysis-operations.interface';
import { SourceEnum } from 'src/app/interfaces/confirmationDrawee.interface';
import { CreditInfoInterface } from 'src/app/interfaces/creadit-limit.interface';
import { FiltersInterface } from 'src/app/interfaces/filters.interface';
import { OperationLead } from 'src/app/interfaces/operacaoFilters';
import { buyerDashboardService } from 'src/app/services/buyer-dashboard.service';
import { NotifierService } from 'src/app/services/notifier.service';
import { CreateBlob } from 'src/app/utils/create-blob';
import { OperationTypeList } from 'src/app/utils/operationType-list';
import { ModalRegisterCedenteComponent } from '../modal-register-cedente/modal-register-cedente.component';
import { SimulateReceivablesModalComponent } from '../simulate-receivables-modal/simulate-receivables-modal.component';
import {  ActivatedRoute, Router } from '@angular/router';

interface OperationListInterface extends TitleListInterface {
  dataSource: MatTableDataSource<OperationLead, MatTableDataSourcePaginator>;
}

@Component({
  selector: 'app-analysis-titles-view',
  templateUrl: './analysis-titles-view.component.html',
  styleUrls: ['./analysis-titles-view.component.scss'],
})
export class AnalysisTitlesViewComponent implements OnInit {
  dataSource = new MatTableDataSource<OperationLead>();
  pageIndex: number = 0;
  pageSize: number = 20;
  isLoading = false;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  customerStatus = 'IN_ANALYSIS';
  filters?: FiltersInterface = { status: this.customerStatus };
  leads: OperationLead[] = [];
  sourceOptions = SourceEnum;

  displayedColumns: string[] = [
    'select',
    'confirmationDrawee',
    'Cedente',
    'Sacado',
    'Broker',
    'TipoOperacao',
    'Parcela',
    'responsável',
    'Vencimento',
    'Tipo',
    'Numero',
    'Valor de face',
    'Valor líquido',
    'Observacao',
  ];
  operationList!: OperationListInterface[];
  data!: OperationLead[];
  row!: OperationLead;
  selection = new SelectionModel<OperationLead>(true, []);
  creditInfo?: CreditInfoInterface;
  disableButton: boolean = false;
  currentExpandedItem?: OperationLead;

  constructor(
    private buyerDashboardService: buyerDashboardService,
    public dialog: MatDialog,
    private notifierService: NotifierService,
    private currency: CurrencyPipe,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.getCustomers();
  }

  getCreditInfo(companyId: string) {
    return this.buyerDashboardService.getCreditInfo(companyId);
  }
  snackbarRef: MatSnackBarRef<any> | null = null;
  showSnackbar(item: OperationLead) {
    this.selection.toggle(item);
    const totalAmountSum = this.selection.selected.reduce(
      (sum, lead) => sum + lead.totalAmount,
      0
    );

    if (this.selection.selected.length) {
      this.getCreditInfo(item.leadId).subscribe((creditInfo) => {
        const remainingCredit =
          +creditInfo.creditLimit - +creditInfo.usedCreditLimit;

        this.creditInfo = {
          ...creditInfo,
          remainingCredit: remainingCredit.toString(),
        };

        const remainingCreditAfterOperation =
          +this.creditInfo.remainingCredit - totalAmountSum;
        const description = `${this.currency.transform(
          remainingCredit,
          'BRL'
        )} / ${this.currency.transform(totalAmountSum, 'BRL')}`;

        if (remainingCreditAfterOperation < 0) {
          this.disableButton = true;
          this.snackbarRef = this.notifierService.showAssignorSnackbar({
            message: 'Limite do cedente disponível e valor selecionado',
            alternative: description,
            messageType: 'errorNotifier',
            tooltip:
              'O cedente não tem limite de crédito suficiente para essa antecipação',
          });
        } else {
          this.disableButton = false;
          this.snackbarRef = this.notifierService.showAssignorSnackbar({
            message: 'Limite do cedente disponível e valor selecionado',
            alternative: description,
            messageType: 'successNotifier',
            tooltip:
              'Exibe o limite disponível do cedente antes da confirmação da compra e o valor dos títulos selecionados',
          });
        }
      });
    }
    if (!this.selection.selected.length) {
      this.snackbarRef?.dismiss();
    }
  }

  select(item: OperationLead) {
    this.showSnackbar(item);
  }

  canSelect(title: OperationLead): { canSelect: boolean; message?: string } {
    const currentSelected = this.selection.selected[0];
    if (!currentSelected) return { canSelect: true };
    if (title.cnpjLead !== currentSelected.cnpjLead) {
      return {
        canSelect: false,
        message: 'Não é possível selecionar cedentes diferentes',
      };
    }
    if (title.bankLead !== currentSelected.bankLead) {
      return {
        canSelect: false,
        message: 'Título com dado bancário para recebimento diferente',
      };
    }
    return { canSelect: true };
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.leads.length;
    return numSelected === numRows;
  }

  filterData(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue
      .trim()
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.leads);
  }

  checkboxLabel(row: OperationLead): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      this.dataSource.data.indexOf(row) + 1
    }`;
  }
  openDialog() {
    const currentOperations = this.selection.selected;
    const dialogRef = this.dialog
      .open(SimulateReceivablesModalComponent, {
        data: { currentOperations },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result?.success) {
          this.selection.clear();
          this.handleSubmit({});
        }
      });
  }
  changeTitleStatus(status: string) {
    const selectedItems = this.selection.selected;
    const installments = selectedItems.map((i) => i.id);

    const requestData = {
      installments,
      status,
    };

    this.buyerDashboardService
      .updateInstallments(requestData)
      .pipe(take(1))
      .subscribe(() => {
        this.notifierService.showNotification({
          message: 'Dados Alterados.',
          alternative: 'Titulo descartado com Sucesso!',
          messageType: 'successNotifier',
        });
        this.selection.clear();
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      });
  }

  lastPage = false;
  getCustomers(data?: FiltersInterface) {
    if (data) {
      this.filters = {
        status: this.customerStatus,
        ...data,
      };
    }

    if (!this.lastPage) {
      this.isLoading = true;
      this.buyerDashboardService
        .getAll(this.pageIndex, this.pageSize, this.filters)
        .pipe(
          map(({ content, ...rest}) => {
            const invoicesWithWrongInstallments = this.checkInvoicesWithWrongInstallments(content);

            if(invoicesWithWrongInstallments?.length) {
              invoicesWithWrongInstallments.forEach((invoiceNumber) => {
                content = content.map((invoice) => {
                  return this.correctInvoiceInstallments(invoice, invoiceNumber);
                });
              })
            }
            return { content, ...rest };
        }),
        finalize(() => {
          this.isLoading = false;
        }))
        .subscribe({
          next: (data) => {
            if (this.pageIndex === 0) this.leads = data.content;
            else this.leads = this.leads.concat(data.content);
            if (!data.last) this.pageIndex = this.pageIndex + 1;
            if (data.last) this.lastPage = true;
          },
        });
    }
  }

  handleSubmit(data?: FiltersInterface) {
    this.pageIndex = 0;
    this.lastPage = false;
    this.getCustomers(data);
  }

  openDialogRegisterCedente(row: OperationLead) {
    const { cnpjLead, cnpjSacado } = row;

    const dialogRef = this.dialog.open(ModalRegisterCedenteComponent, {
      data: { cnpj: cnpjLead, cnpjSacado },
    });
  }
  getNameTypeOperation(value: string) {
    return OperationTypeList.getName(value);
  }
  getDownloadFilesOperation(id: string, fileName: string | number) {
    this.buyerDashboardService
      .getDownloadFilesOperation(id)
      .pipe(take(1))
      .subscribe((data: any) => {
        CreateBlob.Do(data, 'zip', fileName.toString());
      });
  }
  clearSelection() {
    this.selection.clear();
    this.snackbarRef?.dismiss();
  }

  getPercentInfo(percent: string | number) {
    return +percent * 0.01;
  }

  handlePressRow(row: OperationLead) {
    this.currentExpandedItem = (this.currentExpandedItem !== row) ? row : undefined;
  }

  closeAside() {
    this.currentExpandedItem = undefined;
  }

  navigateTo(url: string){
    this.router.navigate([url], {
      relativeTo: this.route,
      state: {item: this.currentExpandedItem}
    });
  }

  checkInvoicesWithWrongInstallments(invoice: OperationLead[]): OperationLead['numberInvoice'][] {
    return invoice.filter(invoice => {
      const installmentAsString = String(invoice.numberInstallment);
      const hasWrongInstallment = installmentAsString.startsWith("0") && installmentAsString.charAt(1) === "/";
      return hasWrongInstallment;
    }).map(invoice => invoice.numberInvoice);
  }

  correctInvoiceInstallments(invoice: OperationLead, invoiceNumber: OperationLead['numberInvoice']) {
      if(invoice.numberInvoice === invoiceNumber) {
        const numberInstallmentAsString = String(invoice.numberInstallment);
        const [firstHalf, secondHalf] = numberInstallmentAsString.split('/');
        const correctNumber = +firstHalf + 1;
        const correctInstallmentNumber = String(correctNumber).concat('/', secondHalf);
        return { ...invoice, numberInstallment: correctInstallmentNumber };
      }
     return { ...invoice };
  }
}
