import { Component } from '@angular/core';

@Component({
  selector: 'app-not-authorized',
  templateUrl: './not-authorized.component.html',
  styleUrls: ['./not-authorized.component.scss'],
})
export class NotAuthorizedComponent {
  navigatePLatformBroadfactor() {
    window.open('https://plataforma.broadfactor.com/');
  }
}
