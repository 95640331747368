<div class="main-container">
  <div class="title">
    <h1>Liquidar valor do título</h1>
  </div>
  <p class="label">Preencha o valor total recebido do título.</p>
  <p class="label text">Você já liquidou o valor abaixo.</p>

  <form class="value-info">
    <mat-form-field class="input" fill="outline">
      <input
        [formControl]="currencyFC"
        matInput
        [inputMask]="currencyInputMask"
        placeholder="R$ 0,00"
      />
      <mat-icon matSuffix color="primary">mode_edit</mat-icon>
    </mat-form-field>
  </form>

  <div class="button">
    <button class="actions-cancel" mat-flat-button mat-dialog-close="">
      Cancelar
    </button>
    <button
      class="actions"
      mat-flat-button
      [ngStyle]="{
        'background-color': '#CC3179'
      }"
      (click)="updatePartial()"
    >
      Confirmar
    </button>
  </div>
</div>
