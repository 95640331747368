import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { delay, finalize, take, tap, timer } from 'rxjs';
import { OperationLead } from 'src/app/interfaces/operacaoFilters';
import { AuthService } from 'src/app/services/auth.service';
import { buyerDashboardService } from 'src/app/services/buyer-dashboard.service';
import { NotifierService } from 'src/app/services/notifier.service';

@Component({
  selector: 'app-simulate-receivables-modal',
  templateUrl: './simulate-receivables-modal.component.html',
  styleUrls: ['./simulate-receivables-modal.component.scss'],
})
export class SimulateReceivablesModalComponent implements AfterViewInit {
  currentOperations!: OperationLead[];
  totalAmount!: number;
  amountToPay!: number;
  discount!: number;
  totalNetValue!: number;
  emoluments!: number;
  isLoading: boolean = false;

  constructor(
    private buyerDashboardService: buyerDashboardService,
    public dialogRef: MatDialogRef<SimulateReceivablesModalComponent>,
    private authService: AuthService,
    private _snackBar: MatSnackBar,
    public router: Router,
    private notifierService: NotifierService,

    @Inject(MAT_DIALOG_DATA) public data: { currentOperations: OperationLead[] }
  ) {
    this.currentOperations = data.currentOperations;

    this.totalAmount = data.currentOperations.reduce(
      (total, current) => total + current.totalAmount,
      0
    );
    this.currentOperations = data.currentOperations;

    this.amountToPay = data.currentOperations.reduce(
      (total, current) => total + current.amountToPay,
      0
    );

    this.discount = this.totalAmount - this.amountToPay;
  }
  getEmoluments() {
    this.buyerDashboardService
      .getEmoluments(this.currentOperations.map((operation) => operation.id))
      .subscribe((response) => {
        // valor líquido total
        // somatoria de amountToPay menos emolumentos

        this.emoluments = response.emoluments;
        this.totalNetValue =
          this.currentOperations.reduce(
            (total, current) => total + current.amountToPay,
            0
          ) - this.emoluments;
      });
  }
  ngAfterViewInit() {
    this.getEmoluments();
  }

  changeTitleStatus() {
    const installments = this.currentOperations.map((i) => i.id);
    const buyerId = this.authService.getAuth().auth;
    const status = 'DOCUMENT';
    const requestData = {
      installments,
      status,
      buyerId,
    };
    this.isLoading = true;

    this.buyerDashboardService
      .updateInstallments(requestData)
      .pipe(
        take(1),
        finalize(() => {
          timer(1000).subscribe(() => {
            this.isLoading = false;
          })
      }))
      .subscribe({
        next: () => {
          this.notifierService.showNotification({
            message: 'Dados Alterados.',
            alternative: 'Titulo alterado com Sucesso!',
            messageType: 'successNotifier',
          });
          this.dialogRef.close({ success: true });
        },
        error: (err) => {
          let message = 'Erro ao salvar as alterações!';

          if (err?.error?.message === 'ACTION_NOT_ALLOWED') {
            message = 'Não é possível alterar o status.';
          }
          this.notifierService.showNotification({
            message,
            alternative: 'Contate a operação.',
            messageType: 'errorNotifier',
          });
        },
      });
  }
}
