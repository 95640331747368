<mat-card
  [ngClass]="[size, apparance]"
  class="d-flex flex-column justify-content-between align-items-start mb-4"
>
  <mat-card-header class="d-flex align-items-center pb-3 w-100">
    <mat-icon class="mat-18 me-3">{{ icon }}</mat-icon>
    <div>
      <mat-card-subtitle class="d-flex ">
        {{ title ?? 'N/A'}}
        <mat-icon
          class="help-icon .bg-secondary"
          *ngIf="toolTip"
          [matTooltip]="toolTip"
          matTooltipPosition="below"
        >
          help_outiline
        </mat-icon>
      </mat-card-subtitle>
      <mat-card-title>{{ subtitle }}</mat-card-title>
    </div>
  </mat-card-header>

  <mat-card-content *ngIf="hasContent" class="w-100">
    <ng-content select="[content]">
    </ng-content>
  </mat-card-content>
</mat-card>
