import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotifierComponent } from '../components/notifier/notifier.component';
import { NotifierInterface } from '../interfaces/notifier.interface';
import { AssignorSnackbarComponent } from '../components/assignor-snackbar/assignor-snackbar.component';

@Injectable({
  providedIn: 'root',
})
export class NotifierService {
  constructor(private snackBar: MatSnackBar) {}

  showNotification({ messageType, ...data }: NotifierInterface) {
    let config: object = {
      data,
      duration: 5000,
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
    };
    if (messageType) config = { ...config, panelClass: [messageType] };
    this.snackBar.openFromComponent(NotifierComponent, config);
  }

  showAssignorSnackbar(data: NotifierInterface) {
    let config: object = {
      data,
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
    };
    if (data.messageType) {
      config = {
        ...config,
        panelClass: ['assignor-snackbar', data.messageType],
      };
    }
    return this.snackBar.openFromComponent(AssignorSnackbarComponent, config);
  }
}
