import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent {
  @Input() apparance: 'default' | 'black' | 'danger' = 'default';
  @Input() icon: string = '';
  @Input() title: string = '';
  @Input() subtitle?: string | number = '';
  @Input() size: 'small' | 'medium' | 'larger' = 'small';
  @Input() hasContent: boolean = false;
  @Input() toolTip = '';
  constructor() { }



}
