import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { createMask } from '@ngneat/input-mask';
import { buyerDashboardService } from 'src/app/services/buyer-dashboard.service';
import { NotifierService } from 'src/app/services/notifier.service';

@Component({
  selector: 'app-modal-liquidated-partinal',
  templateUrl: './modal-liquidated-partinal.component.html',
  styleUrls: ['./modal-liquidated-partinal.component.scss'],
})
export class ModalLiquidatedPartinalComponent {
  formUpdate!: FormGroup;
  currencyFC!: FormControl;
  constructor(
    private buyerDashboardService: buyerDashboardService,
    private _snackBar: MatSnackBar,
    private notifierService: NotifierService,
    public dialogRef: MatDialogRef<ModalLiquidatedPartinalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { installmentId: string; inputValue: string | number }
  ) {
    this.currencyFC = new FormControl('');
  }
  currencyInputMask = createMask({
    alias: 'numeric',
    groupSeparator: '.',
    radixPoint: ',',
    digits: 2,
    digitsOptional: false,
    min: 0,
    prefix: 'R$ ',
    placeholder: '0',
  });

  isMaskedValue(value: string) {
    return value.includes('R$') || value.includes(',');
  }

  updatePartial() {
    let installmentValue = this.currencyFC.value;
    const isMaskedValue = this.isMaskedValue(installmentValue);
    if (isMaskedValue) {
      installmentValue = this.currencyFC.value
        ?.toString()
        .replace(/['R$'|' '|'.']/g, '') // remover R$ e espaço em branco
        .replace(',', '.'); // trocar vírgula por ponto
    }
    this.buyerDashboardService
      .updatePartial({
        installment: this.data.installmentId,
        value: installmentValue as string,
      })
      .subscribe(() => {
        this.notifierService.showNotification({
          message: 'Dados Alterados.',
          alternative: ' Valor liquidado com sucesso!',
          messageType: 'successNotifier',
        });
        this.dialogRef.close({ success: true });
      });
  }

  ngOnInit() {
    this.currencyFC.setValue(this.data.inputValue.toString().replace('.', ','));
  }
}
