import { Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BanksInterface } from 'src/app/interfaces/companyData.interface';

@Component({
  selector: 'app-banking-information-modal',
  templateUrl: './banking-information-modal.component.html',
  styleUrls: ['./banking-information-modal.component.scss'],
})
export class BankingInformationModalComponent {
  confirmation = new FormControl(false);

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA)
    public data: BanksInterface
  ) {}

  confirm() {
    this.dialogRef.close({ confirmed: true });
  }
}
