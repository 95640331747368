export class CategoryList{
    static list(){
        return [
            {name:'Comissão', entryType: 'EXPENSE', accountType: 'COMMISSION', class: 'category-commission'},
            {name:'Créditos Comissão', entryType: 'DISCOUNT', accountType: 'COMMISSION', class: 'category-credit'},
            {name:'Documentos', entryType: 'EXPENSE', accountType: 'DOC_SIGN', class: 'category-docs'},
            {name:'Crédito Documentos', entryType: 'DISCOUNT', accountType: 'DOC_SIGN', class: 'category-credit'},
            
        ]
    }
}