<div class="container" [ngClass]="data.messageType">
  <div class="container-item">
    <div class="container-item-message-whopper">
      <span class="container-item-message">
        {{ data.message }}
      </span>
      <mat-icon *ngIf="data.tooltip" [matTooltip]="data.tooltip">help</mat-icon>
    </div>
    <span class="container-item-alternative">
      {{ data.alternative }}
    </span>
  </div>

  <button
    *ngIf="data.dismissMessage"
    (click)="snackBarRef.dismiss()"
    mat-stroked-button
  >
    {{ data.dismissMessage }}
  </button>
</div>
