import { Component, OnInit, ViewChild } from '@angular/core';

import { FormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import moment, { Moment } from 'moment';
import { Router } from '@angular/router';
import { FinancialService } from 'src/app/services/financial.service';
import {
  FinancialSummary,
  FinancialSummaryContent,
} from 'src/app/interfaces/financial.interface';
import {
  Observable,
  Subject,
  catchError,
  delay,
  iif,
  map,
  mergeMap,
  of,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { NotifierService } from 'src/app/services/notifier.service';

@Component({
  selector: 'app-financial',
  templateUrl: './financial.component.html',
  styleUrls: ['./financial.component.scss'],
})
export class FinancialComponent implements OnInit {
  data!: FinancialSummary | null;
  dateMoment = new FormControl(moment());
  dateSelected!: Moment;
  error$ = new Subject<boolean>();
  private unsubscribe = new Subject<void>();
  constructor(
    private financialService: FinancialService,
    private router: Router,
    private notifierService: NotifierService
  ) {}

  ngOnInit(): void {
    this.dateSelected = sessionStorage.getItem('dateSelected')
      ? moment(sessionStorage.getItem('dateSelected'))
      : moment();
    this.getDataFinance(this.dateSelected);
  }

  getDataFinance(dataSelected: Moment) {
    this.error$.next(false);
    this.data = null;
    const tenantId = this.financialService.getTenantId();
    this.financialService
      .getSummary(dataSelected, tenantId)
      .pipe(
        take(1),
        takeUntil(this.unsubscribe),
        catchError((error) => {
          console.error(error);

          this.error$.next(true);
          return of();
        })
      )
      .subscribe((summary) => {
        if (summary.content.length === 0) {
          this.error$.next(true);
          this.notifierService.showNotification({
            message: 'Mês/Ano não encontrado!',
            alternative: 'Insira uma data válida.',
            messageType: 'errorNotifier',
          });
          return;
        }
        this.data = summary;
      });
  }

  setMonthAndYear(
    normalizedMonthAndYear: Moment,
    datepicker: MatDatepicker<Moment>
  ) {
    const ctrlValue = this.dateMoment.value ?? moment();
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    this.dateMoment.setValue(ctrlValue);
    datepicker.close();
  }

  redirectTo(dateParameter: Moment) {
    sessionStorage.setItem('dateSelected', dateParameter.toISOString());
    this.router.navigate(['/financeiro/detalhes']);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
