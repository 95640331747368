<mat-sidenav
  #asideRight
  class="aside-right"
  disableClose
  position="end"
  [opened]="opened"
>
  <div class="header">
    <div class="close-container">
      <h2>Detalhes</h2>
      <button mat-icon-button class="mt-n2" (click)="close()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <ng-content select="[header]"></ng-content>
  </div>
  <div class="content">
    <ng-content select="[content]"></ng-content>
  </div>

  <div class="footer">
    <ng-content select="[footer]"></ng-content>
  </div>
</mat-sidenav>
