<div class="main-container">
  <p class="alert-text">
    Os dados bancários para pagamento serão exibidos após a confirmação de
    compra.
  </p>
  <div class="header">
    <div class="label-container">
      <p class="label">Cedente</p>
    </div>

    <div class="label-container">
      <p class="label">Aditivo</p>
    </div>

    <div class="label-container">
      <p class="label">Valor Bruto</p>
    </div>

    <div class="label-container">
      <p class="label">Valor Líquido</p>
      <p class="label">
        <!-- {{ operation.amountPaid | currency : "BRL" }} -->
      </p>
    </div>
  </div>

  <mat-accordion *ngFor="let document of operationList">
    <mat-expansion-panel>
      <mat-expansion-panel-header class="dropdown-header">
        <div
          class="dropdown-label-container"
          matTooltip="Só é possível selecionar uma operação por vez para confirmar compra"
          [matTooltipDisabled]="
            !selection.selected.length ||
            selection.selected[0].additive === document.additive
          "
        >
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(document) : null"
            [checked]="selection.isSelected(document)"
            [disabled]="
              (!!selection.selected.length &&
                selection.selected[0]?.additive !== document?.additive) ||
              document.installments[0].hasPending
            "
          >
          </mat-checkbox>
        </div>
        <div
          class="dropdown-label-container"
          [matTooltipPosition]="'left'"
          matTooltip="Título está em análise pelo time de operação devido à solicitação de desistência da antecipação."
          [matTooltipDisabled]="!document.installments[0].hasPending"
        >
          <p class="dropdown-label">
            {{ document.installments[0].nameLead }}
          </p>
        </div>

        <div class="dropdown-label-container">
          <p class="dropdown-label"></p>
          <p
            class="link"
            (click)="
              openDialogAditive(
                document.additive,
                document.installments[0].nameLead
              )
            "
          >
            {{ document.additive }}
          </p>
        </div>

        <div class="dropdown-label-container">
          <p class="dropdown-label">
            {{ document.totalAmount | currency : "BRL" }}
          </p>
        </div>

        <div class="dropdown-label-container">
          <p class="dropdown-label">
            {{ document.amountToPay || 0 | currency : "BRL" }}
          </p>
          <p class="dropdown-label"></p>
        </div>
      </mat-expansion-panel-header>

      <section class="container-table mat-elevation-z8" tabindex="0">
        <div class="table-container">
          <table mat-table [dataSource]="document.dataSource">
            <ng-container matColumnDef="confirmationDrawee">
              <th mat-header-cell *matHeaderCellDef>
                <button
                  class="table-button"
                  mat-icon-button
                  matTooltip="Nota com confirmação do sacado."
                >
                  <mat-icon>task</mat-icon>
                </button>
              </th>
              <td mat-cell *matCellDef="let row">
                <button
                  class="table-button"
                  mat-icon-button
                  *ngIf="row.source === sourceOptions.BASE_B"
                  matTooltip="Nota com confirmação do sacado."
                >
                  <mat-icon>task</mat-icon>
                </button>
              </td>
            </ng-container>
            <ng-container matColumnDef="sacados">
              <th mat-header-cell *matHeaderCellDef>Sacados</th>
              <td mat-cell *matCellDef="let element">
                {{ element.nameSacado ?? "N/A" }}
              </td>
            </ng-container>
            <ng-container matColumnDef="broker">
              <th mat-header-cell *matHeaderCellDef>Broker</th>
              <td mat-cell *matCellDef="let element">
                {{ element.nameBroker ?? "N/A" }}
              </td>
            </ng-container>
            <ng-container matColumnDef="TipoOperacao">
              <th mat-header-cell *matHeaderCellDef>Tipo operação</th>
              <td mat-cell *matCellDef="let element">
                {{ getNameTypeOperation(element.operationType) ?? "N/A" }}
              </td>
            </ng-container>

            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef>Tipo</th>
              <td mat-cell *matCellDef="let element">
                {{ element.type ?? "N/A" }}
              </td>
            </ng-container>

            <ng-container matColumnDef="dueDateEnd">
              <th mat-header-cell *matHeaderCellDef>Vencimento</th>
              <td mat-cell *matCellDef="let element">
                {{ element.dueDate ?? "N/A" }}
              </td>
            </ng-container>

            <ng-container matColumnDef="totalAmount">
              <th mat-header-cell *matHeaderCellDef>Valor de Face</th>
              <td mat-cell *matCellDef="let element">
                {{ (element.totalAmount || 0 | currency : "BRL") ?? "N/A" }}
              </td>
            </ng-container>
            <ng-container matColumnDef="valueLiquid">
              <th mat-header-cell *matHeaderCellDef>Valor líquido</th>
              <td mat-cell *matCellDef="let element">
                {{ (element.amountToPay || 0 | currency : "BRL") ?? "N/A" }}
              </td>
            </ng-container>

            <ng-container matColumnDef="observation">
              <th mat-header-cell *matHeaderCellDef>Observação</th>
              <td mat-cell *matCellDef="let element">
                {{ element.obs ?? "N/A" }}
              </td>
            </ng-container>

            <tr
              mat-header-row
              *matHeaderRowDef="displayedColumns; sticky: true"
            ></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayedColumns"
              (click)="handlePressRow(row)"
              class="element-row"
              [class.expanded-row]="currentExpandedItem === row"
            ></tr>
          </table>
        </div>
      </section>
    </mat-expansion-panel>
  </mat-accordion>
  <div class="spinner-container" *ngIf="isLoading">
    <mat-spinner class="spinner" color="accent"></mat-spinner>
  </div>
  <div
    class="container-blankslate"
    *ngIf="!isLoading && operationList.length == 0"
  >
    <p>Você não possui títulos para serem exibidos nessa situação.</p>
  </div>
  <mat-toolbar class="mat-elevation-z4 selection-header">
    <button
      (click)="openDialogBankingInformation()"
      mat-flat-button
      [disabled]="selection.selected.length === 0"
      class="button-simulate"
      [ngStyle]="{
        'background-color':
          selection.selected.length > 0 ? '#CC3179' : 'rgba(204, 49, 121, 0.38)'
      }"
    >
      Confirmar pagamento
    </button>
    <span class="example-spacer"></span>
    <span *ngIf="selection.selected.length > 0">
      {{ selection.selected.length }}
      <span *ngIf="selection.selected.length === 1"> Título selecionado </span>
      <span *ngIf="selection.selected.length > 1"> Títulos selecionados </span>
    </span>
    <button
      mat-icon-button
      (click)="clearSelection()"
      *ngIf="selection.selected.length > 0"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
</div>

<app-aside [opened]="!!currentExpandedItem" (isClose)="closeAside()">
  <section header>

    <div class="flex-column">
      <span class="text-black"><strong>{{currentExpandedItem?.nameLead}}</strong></span>
      <span class="text-black">{{currentExpandedItem?.cnpjLead | cnpj}}</span>
    </div>

    <div class="flex-column">
      <span class="text-black">Número do Título: {{currentExpandedItem?.numberInvoice}}</span>
      <span class="text-black">Taxa: {{(getPercentInfo(currentExpandedItem?.fee!) | percent : "1.0-2")}}</span>
      <span class="text-black">Valor de Face: {{currentExpandedItem?.totalAmount | currency : "BRL" }}</span>
      <span class="text-black">Valor Líquido: {{currentExpandedItem?.amountToPay | currency : "BRL" }}</span>
    </div>

    <div class="badge-green">
      Aguardando Assinatura
    </div>
  </section>

  <section content>
    <div class="flex-row">
      <button
        mat-stroked-button
        class="sidenav-btn width-50"
        (click)="openDialogRegisterCedente(currentExpandedItem!)"
      >
        <mat-icon>assignment</mat-icon>
        Dados cadastrais
      </button>
      <button
        mat-stroked-button
        class="sidenav-btn width-50"
        (click)="navigateTo('dados-operacionais')"
      >
        <mat-icon>insert_chart</mat-icon>
        Dados operacionais
      </button>
    </div>

    <button
      mat-stroked-button
      class="sidenav-btn width-100"
      (click)="getDownloadFilesOperation(currentExpandedItem!.id, currentExpandedItem!.numberInvoice!)"
    >
      <mat-icon>file_download</mat-icon>
      Baixar NF e documentos adicionais
    </button>
  </section>
</app-aside>
