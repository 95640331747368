<mat-toolbar class="toolbar">
   
      <div class="toolbar-icon" routerLink="/financeiro">
        <mat-icon   class="toolbar-icon"> arrow_back_ios</mat-icon>   
      </div>
      <span>Detalhes</span>
</mat-toolbar>  

<div class="container">
  <div class="container-info">
      <div class="container-info-lead">
        <span class="container-info-lead-date">{{dateSelected.locale('pt-br').format('MMMM/YYYY') | titlecase}}</span>
        <span class="container-info-lead-name">{{company}}</span>
        
        <span class="container-info-lead-comission">O Comissionamento da Broadfactor será de 1% do valor liquidado</span>
      </div>

      
      <button mat-button mat-raised-button class="container-info-action" (click)="dowloandPDF('#', '#')">
          <span>Baixar extrato</span>
          <mat-icon>download</mat-icon>
      </button>
  </div>
        
       
 <form class="container-filter" [formGroup]="inputsFilter" >

    <mat-form-field class="form-field container-filter-1th" appearance="outline" >
        <mat-label>
          <mat-icon>search</mat-icon>
          Cedente
        </mat-label>
        <input
          placeholder="Digite o CNPJ ou a Razão Social"
          matInput
          type="text" 
          formControlName="cedente"
          [matAutocomplete]="auto"
          #cedente
          (input)="onInput(cedente.value, 'LEAD')"
          (blur)="validationInpuLead()"
        />
        <mat-autocomplete  
          #auto="matAutocomplete"
          [displayWith]="displayLead.bind(this)" >
          <mat-option *ngFor="let option of leadOptions" [value]="option" >
            {{option.name}} - {{option.cnpj}}
          </mat-option>
        </mat-autocomplete>
    </mat-form-field>

    <mat-form-field class="form-field container-filter-1th" appearance="outline" style="margin: 0;" >
      <mat-label>
        <mat-icon>search</mat-icon>
        Sacado
      </mat-label>
      <input
        placeholder="Digite o CNPJ ou a Razão Social"
        matInput
        type="text" 
        formControlName="sacado"
        [matAutocomplete]="auto_drwaee"
        #drawee
        (input)="onInput(drawee.value, 'SACADO')"
        (blur)="validationInputSacado()"
      />
      <mat-autocomplete  
        #auto_drwaee="matAutocomplete"
        [displayWith]="displayDrawee.bind(this)" >
        <mat-option *ngFor="let option of draweeOptions" [value]="option" >
          {{option.name}} - {{option.cnpj}}
        </mat-option>
      </mat-autocomplete>
  </mat-form-field>

    <mat-form-field class="form-field container-filter-2th" appearance="outline">
        <mat-label>
          <mat-icon>search</mat-icon>
          Título
        </mat-label>
        <input
          formControlName="title"
          placeholder="Digite o Nº do título"
          matInput
          type="text"         
        />
    </mat-form-field>
    <mat-form-field class="form-field container-filter-3th" appearance="outline">
        <mat-label>
          <mat-icon>search</mat-icon>
          Aditivo
        </mat-label>
        <input
          formControlName="additive"
          placeholder="Digite o aditivo"
          matInput
          type="text"
        />
    </mat-form-field>

    <mat-form-field class="container-filter-4th">
        <mat-label>Categoria</mat-label>
        <mat-select         
          formControlName="category"
          placeholder="Selecione"
        >
          <mat-option *ngFor="let option of categoryOptions" [value]="option">{{option.name}}</mat-option>
          
        </mat-select>
    </mat-form-field>
    
    <button 
      mat-stroked-button 
      class="container-filter-btn"
      type="submit"
      (click)="onFilter()"
    > 
      <mat-icon>filter_alt</mat-icon> Filtrar
    </button>

    <button class="container-filter-clear" (click)="clearFilters()"> <mat-icon>filter_alt_off</mat-icon> </button>
 </form>

  <div class="container-table" 
    *ngIf="!errorHandling.errorNotFind; else notFindTemplate"
    tabindex="0"
    infiniteScroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="50"
    (scrolled)="onTableScroll()"
    [scrollWindow]="false"
  >
     
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" *ngIf=" dataSource.length !== 0;  else dataNull">
 
        <ng-container matColumnDef="cedente">
          <th mat-header-cell *matHeaderCellDef> Cedente </th>
          <td mat-cell *matCellDef="let element"> {{element.lead | uppercase}} </td>
        </ng-container>

        <ng-container matColumnDef="sacado">
          <th mat-header-cell *matHeaderCellDef> Sacado </th>
          <td mat-cell *matCellDef="let element"> {{(element.drawee | uppercase) || 'N/A'}} </td>
        </ng-container>

        <ng-container matColumnDef="face">
          <th mat-header-cell *matHeaderCellDef> Valor de Face </th>
          <td mat-cell *matCellDef="let element"> {{(element.faceValue | currency :'BRL')|| 'N/A'}} </td>
        </ng-container>

        <ng-container matColumnDef="liquidado">
          <th mat-header-cell *matHeaderCellDef> Valor Liquidado </th>
          <td mat-cell *matCellDef="let element"> {{(element.paidValue | currency :'BRL') || 'N/A'}} </td>
        </ng-container>

        <ng-container matColumnDef="vencimento">
            <th mat-header-cell *matHeaderCellDef> Vencimento </th>
            <td mat-cell *matCellDef="let element"> {{(element.expirationDate  | date:'dd/MM/YYYY') || 'N/A'}} </td>
        </ng-container>

        <ng-container matColumnDef="numero">
            <th mat-header-cell *matHeaderCellDef> Nº de Título </th>
            <td mat-cell *matCellDef="let element"> {{(element.installmentNumber) || 'N/A' }} </td>
        </ng-container>
    
        <ng-container matColumnDef="data">
            <th mat-header-cell *matHeaderCellDef>
              Data
              <mat-icon matTooltip="Data de liquidação ou postagem dos documentos" >help</mat-icon>
            </th>
            <td mat-cell *matCellDef="let element"> {{element.publicationDate | date:'dd/MM/YYYY'}} </td>
        </ng-container>
       
        <ng-container matColumnDef="documento">
            <th mat-header-cell *matHeaderCellDef> Documento </th>
            <td mat-cell *matCellDef="let element"> {{getDocumentName(element.documentType) || 'N/A'}} </td>
        </ng-container>
       
        <ng-container matColumnDef="valor">
            <th mat-header-cell *matHeaderCellDef> Valor </th>
            <td mat-cell *matCellDef="let element"> {{element.entryValue | currency :'BRL'}} </td>
        </ng-container>
   
        <ng-container matColumnDef="aditivo">
            <th mat-header-cell *matHeaderCellDef> Aditivo </th>
            <td mat-cell *matCellDef="let element">{{element.additive}} </td>
        </ng-container>
     
        <ng-container matColumnDef="categoria">
            <th mat-header-cell *matHeaderCellDef> Categoria </th>
            <td mat-cell *matCellDef="let element" class="category" > <div [ngClass]="getClassCategory(element)">{{getCategoryName(element)}}</div> </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>  
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        
      </table>
    
    <ng-template #dataNull >
      <div class="error-container" *ngIf="errorHandling.errorRequest; else loading">
        <div class="error-container-item">
          <mat-icon>report_problem</mat-icon>
          <h1>Problema ao carregar a tabela. </h1>
          <p>Tente novamente mais tarde</p>
        </div>
      </div>


      <ng-template #loading>
        <div class="loading">
          <mat-spinner [diameter]="40"></mat-spinner>
        </div>
      </ng-template>
    </ng-template>


  </div>
  
  <ng-template #notFindTemplate>
    <div class="not-find">
      <img src="assets/png/404.png" alt="Imagem com uma moça segurando uma lupa">
      <span><strong>Não há informações </strong>correspondentes aos valores do <strong>filtro inserido</strong>.</span>
    </div>
  </ng-template>

  <div class="container-footer">
    <div class="container-footer-total" >
      <span>Total:</span>
      <span *ngIf="totalValue; else notFindSkeleton">{{totalValue | currency :'BRL'}}</span>
    </div>
  </div>



</div>

<ng-template #notFindSkeleton >

  <ngx-skeleton-loader 
      count="1"
      appearance="line"
      animation="progress"
      [theme]="{
        'background-color': '#BFBFBF',
        width:'120px',
        height:'20px',
        margin: '0'
      }"
      *ngIf="!(errorHandling.errorRequest || errorHandling.errorNotFind);else notFindData"
    >
    </ngx-skeleton-loader>

    <ng-template #notFindData>
      <div id="notFind">#####</div>

    </ng-template>
</ng-template>