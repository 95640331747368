import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SignatureDocumentsModalComponent } from '../signature-documents-modal/signature-documents-modal.component';

@Component({
  selector: 'app-anticipation-realized-modal',
  templateUrl: './anticipation-realized-modal.component.html',
  styleUrls: ['./anticipation-realized-modal.component.scss'],
})
export class AnticipationRealizedModalComponent {
  constructor(public dialog: MatDialog) {}
  openDialogDocuments() {
    const dialogRef = this.dialog.open(SignatureDocumentsModalComponent);

    dialogRef.afterClosed().subscribe((result) => {});
  }
}
