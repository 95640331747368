import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  MatTableDataSource,
  MatTableDataSourcePaginator,
} from '@angular/material/table';
import { take } from 'rxjs';
import { TitleListInterface } from 'src/app/interfaces/analysis-operations.interface';
import {
  DocumentInstallmentInterface,
  DocumentInterface,
} from 'src/app/interfaces/documents-status.interface';
import { OperationLead } from 'src/app/interfaces/operacaoFilters';
import { buyerDashboardService } from 'src/app/services/buyer-dashboard.service';
import { CreateBlob } from 'src/app/utils/create-blob';
import { AditivoModalComponent } from '../aditivo-modal/aditivo-modal.component';
import { ModalRegisterCedenteComponent } from '../modal-register-cedente/modal-register-cedente.component';
import { OperationTypeList } from 'src/app/utils/operationType-list';
import { NotifierService } from 'src/app/services/notifier.service';
import { BankingInformationModalComponent } from '../banking-information-modal/banking-information-modal.component';
import { SourceEnum } from 'src/app/interfaces/confirmationDrawee.interface';
import { ActivatedRoute, Router } from '@angular/router';

interface OperationListInterface extends DocumentInterface {
  totalAmount: number;
  dataSource: MatTableDataSource<DocumentInstallmentInterface>;
}

@Component({
  selector: 'app-documents-titles',
  templateUrl: './documents-titles.component.html',
  styleUrls: ['./documents-titles.component.scss'],
})
export class DocumentsTitlesComponent implements OnInit {
  dataSource = new MatTableDataSource<OperationLead>();
  displayedColumns: string[] = [
    'confirmationDrawee',
    'sacados',
    'broker',
    'TipoOperacao',
    'type',
    'dueDateEnd',
    'totalAmount',
    'valueLiquid',
    'observation',
  ];
  isLoading = false;
  leads!: OperationLead[];
  operationList: OperationListInterface[] = [];
  pageIndex: number = 0;
  pageSize: number = 20;
  sourceOptions = SourceEnum;
  currentExpandedItem?: OperationLead;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  selection = new SelectionModel<DocumentInterface>(true, []);

  constructor(
    private buyerDashboardService: buyerDashboardService,
    public dialog: MatDialog,
    private notifierService: NotifierService,
    private router: Router,
    private route: ActivatedRoute,
    private _snackBar: MatSnackBar
  ) {}

  //Essa linha comentada vai ser usada para o status em documentos
  ngOnInit() {
    this.getDocumentOperation();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  filterData(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue
      .trim()
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  }

  getDownloadFilesOperation(id: string, fileName: string | number) {
    this.buyerDashboardService
      .getDownloadFilesOperation(id)
      .pipe(take(1))
      .subscribe((data: any) => {
        CreateBlob.Do(data, 'zip', fileName.toString());
      });
  }

  openDialogAditive(additiveNumber: string, nameLead: string) {
    const dialogRef = this.dialog.open(AditivoModalComponent, {
      data: { additiveNumber, nameLead },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  getDocumentOperation(data?: any) {
    this.isLoading = true;

    this.buyerDashboardService
      .getDocumentOperation()
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.operationList = res.content.map((operation) => ({
            ...operation,
            amountPaid: operation.installments
              .map((i) => i.amountPaid)
              .reduce((p, total) => (total += p)),
            totalAmount: operation.installments
              .map((i) => i.totalAmount)
              .reduce((p, total) => (total += p)),

            dataSource: new MatTableDataSource<DocumentInstallmentInterface>(
              operation.installments
            ),
          }));

          this.isLoading = false;
        },
      });
  }
  openDialogBankingInformation() {
    const bankId = this.selection.selected[0].installments[0].bankLead;
    this.buyerDashboardService.getBankInformation(bankId).subscribe({
      next: (bank) => {
        const dialogRef = this.dialog.open(BankingInformationModalComponent, {
          data: bank,
          autoFocus: false,
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (result?.confirmed) {
            this.changeTitleStatus('PAID');
          }
        });
      },
      error: (err) => {
        let message = 'Erro ao salvar as alterações!';

        if (err?.error?.message === 'ACTION_NOT_ALLOWED') {
          message = 'Não é possível alterar o status.';
        }
        this.notifierService.showNotification({
          message,
          alternative: ' Contate a operação.',
          messageType: 'errorNotifier',
        });
      },
    });
  }

  changeTitleStatus(status: string) {
    const selectedItems = this.selection.selected;

    const installments: string[] = [];

    selectedItems.forEach((selected) =>
      selected.installments.forEach((installment) => {
        installments.push(installment.id);
      })
    );

    const requestData = {
      installments,
      status,
    };

    this.buyerDashboardService
      .updateInstallments(requestData)
      .pipe(take(1))
      .subscribe(() => {
        this.notifierService.showNotification({
          message: 'Dados Alterados.',
          alternative: 'Titulo alterado com Sucesso!',
          messageType: 'successNotifier',
        });

        this.selection.clear();

        this.getDocumentOperation();
      });
  }

  getNameTypeOperation(value: string) {
    return OperationTypeList.getName(value);
  }
  openDialogRegisterCedente(item: OperationLead) {
    const { cnpjLead, cnpjSacado } = item;

    const dialogRef = this.dialog.open(ModalRegisterCedenteComponent, {
      data: { cnpj: cnpjLead, cnpjSacado },
    });
  }
  clearSelection() {
    this.selection.clear();
  }

  getPercentInfo(percent: string | number) {
    return +percent * 0.01;
  }

  handlePressRow(row: OperationLead) {
    this.currentExpandedItem = (this.currentExpandedItem !== row) ? row : undefined;
  }

  closeAside() {
    this.currentExpandedItem = undefined;
  }

  navigateTo(url: string){
    this.router.navigate([url], {
      relativeTo: this.route,
      state: {item: this.currentExpandedItem}
    });
  }
}
