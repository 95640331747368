<div class="not-authorized">
  <div class="card-container">
    <div class="icon">
      <mat-icon>report_problem</mat-icon>
    </div>
    <h1>Problemas no acesso as Antecipações</h1>
    <p>Você não parece autenticado, tente novamente.</p>
    <button
      mat-button
      class="button-error"
      (click)="navigatePLatformBroadfactor()"
    >
      <p class="link">Acessar plataforma Broadfactor</p>
    </button>
  </div>
</div>
