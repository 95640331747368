<div class="container-fluid" *ngIf="!isLoading && !hasError">
  <div class="my-4">
    <div class="d-flex align-item-center" >
      <mat-icon>bar_chart</mat-icon>
      <b class="fix-align-text">Limite de Crédito</b>
    </div>
    <div class="progress my-2">
      <div class="progress-bar" role="progressbar" [style.width]="progressBarWidth" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
    </div>
    <div class="d-flex justify-content-between">
      <span><b>Utilizado: </b>{{ (data.creditLimit?.usedLimit | currency: 'BRL') || 'N/A' }}</span>
      <span><b>Limite Total: </b> {{ (data.creditLimit?.totalLimit | currency: 'BRL') || 'N/A' }}</span>
    </div>
  </div>
  <div class="row">
    <section class="col">
      <div class="mb-2"><b>Valores operados do Cedente</b></div>
      <div class="row">
        <app-card
          [icon]="'monetization_on'"
          [title]="'A vencer na minha carteira'"
          [subtitle]="(data.leadOperatedValuesWithThisBuyer?.toExpire | currency: 'BRL') || 'N/A'"
          [apparance]="'black'"
          class="col"
        >
        </app-card>
        <app-card
          [icon]="'warning'"
          [title]="'Em atraso na minha carteira'"
          [subtitle]="(data.leadOperatedValuesWithThisBuyer?.expired | currency: 'BRL') || 'N/A'"
          [apparance]="'danger'"
          class="col"
        >
        </app-card>
      </div>
    </section>
    <section class="col">
      <div class="mb-2"><b>Valores operados do Cedente na plataforma</b></div>
      <div class="row">
        <app-card
          [icon]="'monetization_on'"
          [title]="'A vencer na plataforma'"
          [subtitle]="(data.leadOperatedValuesOnPlataform?.toExpire | currency: 'BRL') || 'N/A'"
          [apparance]="'black'"
          class="col"
        >
        </app-card>
        <app-card
          [icon]="'warning'"
          [title]="'Em atraso na plataforma'"
          [subtitle]="(data.leadOperatedValuesOnPlataform?.expired | currency: 'BRL') || 'N/A'"
          [apparance]="'danger'"
          class="col"
        >
        </app-card>
      </div>
    </section>
  </div>

  <div class="mt-4 p-2 row">
    <section class="col">
      <app-card
        [icon]="'event'"
        [title]="'Última operação realizada em'"
        [size]="'medium'"
        [hasContent]="true"
      >
        <div content>
          <span>{{(data?.lastOperationDate  | date: 'dd/MM/YYYY' ) || 'N/A'}}</span>
        </div>
      </app-card>

      <app-card
        [icon]="'event'"
        [title]="'Recebíveis a vencer'"
        [size]="'medium'"
        [hasContent]="true"
      >
        <div content>
          <table class="width-table">
            <tr class="small">
              <th>&nbsp;</th>
              <th>Na minha carteira</th>
              <th>Na plataforma</th>
            </tr>
            <tr>
              <td>0-30 dias</td>
              <td>{{ (data.installmentsToExpire?.myWallet?.valueUpTo30Days | currency: 'BRL') || 'N/A' }}</td>
              <td>{{ (data.installmentsToExpire?.plataform?.valueUpTo30Days| currency: 'BRL') || 'N/A' }}</td>
            </tr>

            <tr>
              <td>31-60 dias</td>
              <td>{{ (data.installmentsToExpire?.myWallet?.valueUpTo60Days | currency: 'BRL') || 'N/A'}}</td>
              <td>{{ (data.installmentsToExpire?.plataform?.valueUpTo60Days | currency: 'BRL') || 'N/A'}}</td>
            </tr>

            <tr>
              <td>61-90 dias</td>
              <td>{{ (data.installmentsToExpire?.myWallet?.valueUpTo90Days | currency: 'BRL') || 'N/A'}}</td>
              <td>{{ (data.installmentsToExpire?.plataform?.valueUpTo90Days | currency: 'BRL') || 'N/A'}}</td>
            </tr>

            <tr>
              <td>Mais de 91 dias</td>
              <td>{{ (data.installmentsToExpire?.myWallet?.valueMoreThan91Days | currency: 'BRL') || 'N/A'}}</td>
              <td>{{ (data.installmentsToExpire?.plataform?.valueMoreThan91Days | currency: 'BRL') || 'N/A'}}</td>
            </tr>
          </table>
        </div>
      </app-card>
    </section>

    <section class="col">
      <app-card
        [icon]="'verified'"
        [title]="'Recebíveis Liquidados'"
        [size]="'medium'"
        [hasContent]="true"
      >
        <div content>
          <b>Em dia</b>
          <table class="mb-4 width-table">
            <tr class="small">
              <th>&nbsp;</th>
              <th>Na Minha Carteira</th>
              <th>Na Plataforma</th>
            </tr>
            <tr>
              <td>Quantidade</td>
              <td>{{data.settledInstallments?.settledOnMyWallet?.quantity || 'N/A'}}</td>
              <td>{{data.settledInstallments?.settledOnPlataform?.quantity || 'N/A'}}</td>
            </tr>

            <tr>
              <td>Valor liquidado</td>
              <td>{{ (data.settledInstallments?.settledOnMyWallet?.value | currency: 'BRL') || 'N/A'}}</td>
              <td>{{ (data.settledInstallments?.settledOnPlataform?.value | currency: 'BRL') || 'N/A'}}</td>
            </tr>
          </table>

          <b>Em atraso</b>
          <table>
            <tr class="small">
              <th>&nbsp;</th>
              <th>Na Minha Carteira</th>
              <th>Na Plataforma</th>
            </tr>
            <tr>
              <td>Quantidade</td>
              <td>{{data.settledInstallments?.lateOnMyWallet?.quantity || 'N/A'}}</td>
              <td>{{data.settledInstallments?.lateOnPlataform?.quantity || 'N/A'}}</td>
            </tr>

            <tr>
              <td>Valor liquidado</td>
              <td>{{ (data.settledInstallments?.lateOnMyWallet?.value | currency: 'BRL') || 'N/A'}}</td>
              <td>{{ (data.settledInstallments?.lateOnPlataform?.value | currency: 'BRL') || 'N/A'}}</td>
            </tr>
          </table>
        </div>
      </app-card>
    </section>

    <section class="col">
      <app-card
        [icon]="'bar_chart'"
        [title]="'Total de recebíveis operados na plataforma'"
        [size]="'medium'"
        [hasContent]="true"
      >
        <div content>
          <div class="w-100">
            <div class="d-flex justify-content-between">
              <span>Quantidade:</span>
              <span>{{data.totalReceivablesOperatedOnPlataform?.quantity || 'N/A' }}</span> </div>
            <div class="d-flex justify-content-between">
              <span>Valor operado:</span>
              <span>{{ (data.totalReceivablesOperatedOnPlataform?.value | currency: 'BRL') || 'N/A' }} </span>
            </div>
          </div>
        </div>
      </app-card>

      <app-card
        [icon]="'percent'"
        [title]="'Concentração do Cedente na minha carteira'"
        [size]="'medium'"
        [hasContent]="true"
        [toolTip]="'Concentração dos títulos do Cedente na minha carteira, ou seja, percentual de títulos que minha empresa antecipou deste Cedente em comparação com todas as operações que realizamos na plataforma.'"
      >
        <div content>
          <div class="w-100">
            <div class="d-flex justify-content-between">
              <span>A vencer:</span>
              <span>{{ (getPercentInfo(data.percentualOperated?.totalToExpireWithThisLead ?? 0) | percent : "1.0-2") || 'N/A'}} </span> </div>
            <div class="d-flex justify-content-between">
              <span>Total operado:</span>
              <span>{{ (getPercentInfo(data.percentualOperated?.totalOperatedWithThisLead ?? 0) | percent : "1.0-2") || 'N/A'}} </span>
            </div>
          </div>
        </div>
      </app-card>
    </section>
  </div>
  <div class="my-4">
    <b>Contato(s) do Cedente</b>
    <div class="row p-2" *ngFor="let contact of contactList" >
      <div class="col d-flex flex-column">
        <small class="text-muted">Nome</small>
        <span>{{contact?.name ?? '-'}}</span>
      </div>
      <div class="col d-flex flex-column">
        <small class="text-muted">E-mail</small>
        <span>{{contact?.mail ?? '-'}}</span>
      </div>
      <div class="col d-flex flex-column">
        <small class="text-muted">Telefone</small>
        <span>{{contact?.phone ?? '-'}}</span>
      </div>
    </div>
    <div *ngIf="!contactList.length">
      <span class="text-muted">Nenhum contato cadastrado.</span>
    </div>
  </div>
</div>

<div
  *ngIf="isLoading || hasError"
  class="d-flex justify-content-center align-items-center container-fluid">
  <mat-spinner *ngIf="isLoading && !hasError"></mat-spinner>
  <b *ngIf="hasError" class="text-muted h6">Ocorreu um erro ao exibir os dados.</b>
</div>


