import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { take } from 'rxjs';
import {
  AddressInterface,
  CompanyDataInterface,
  DocumentInterface,
} from 'src/app/interfaces/companyData.interface';
import { CreditInfoInterface } from 'src/app/interfaces/creadit-limit.interface';
import { buyerDashboardService } from 'src/app/services/buyer-dashboard.service';

@Component({
  selector: 'app-modal-register-cedente',
  templateUrl: './modal-register-cedente.component.html',
  styleUrls: ['./modal-register-cedente.component.scss'],
})
export class ModalRegisterCedenteComponent implements OnInit {
  cnpj!: string;
  cnpjSacado!: string;
  companyData!: CompanyDataInterface;
  sacadoData!: CompanyDataInterface;
  documentStatusStyle: any;
  creditInfo!: CreditInfoInterface;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { cnpj: string; cnpjSacado: string },
    private buyerDashboardService: buyerDashboardService,
    public dialogRef: MatDialogRef<any>
  ) {
    this.cnpj = data.cnpj;
    this.cnpjSacado = data.cnpjSacado;
  }

  ngOnInit(): void {
    this.dialogRef.updateSize('75%', '80%');

    this.getCompanyData(this.cnpj);
  }
  documents!: DocumentInterface[];

  getCompanyData(cnpj: string) {
    this.buyerDashboardService.getCompanyData(cnpj).subscribe((data) => {
      this.companyData = data;
      this.documents = this.companyData.documents.filter(
        (doc) => doc.status === 'APPROVED'
      );
      
      this.getCreditInfo(data.id);

      this.buyerDashboardService
        .getCompanyData(this.cnpjSacado)
        .subscribe((sacadoData) => {
          this.sacadoData = sacadoData;
        });
    });
  }
  getAddressInfo(address: AddressInterface) {
    if (address.number) {
      return `${address.street}, ${address.number}`;
    }
    return address.street;
  }

  getContactName(type: string) {
    if (type === 'MAIL') return 'Email';
    return 'Telefone';
  }
  loadingDocument = true;

  url!: string | null;
  currentDoc!: DocumentInterface;
  getDocument(type: 'company' | 'partner', doc: DocumentInterface) {
    const path = doc.file;
    this.loadingDocument = true;
    this.buyerDashboardService
      .getSignedDocuments(type, path)
      .pipe(take(1))
      .subscribe(
        (signedUrl) => {
          this.currentDoc = doc;
          this.url = signedUrl;

          this.loadingDocument = false;
        },
        (err) => {
          this.url = null;
          this.loadingDocument = false;
        }
      );
  }

  closeModal() {
    this.dialogRef.close();
  }

  getCreditInfo(companyId: string) {
    this.buyerDashboardService.getCreditInfo(companyId).subscribe((creditInfo) => {
      const remainingCredit =
        +creditInfo.creditLimit - +creditInfo.usedCreditLimit;

      this.creditInfo = {
        ...creditInfo,
        remainingCredit: remainingCredit.toString(),
      };
    });
  }
}
