import { Component, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'customer-dashboard-front';
  @ViewChild('sidenav') sidenav!: MatSidenav;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  navigateTo(page: string) {
    // Use o router para navegar para a página desejada
    this.router.navigate([page]);
  }

  isRouterActive(route: string): boolean {
    return this.router.isActive(route, true);
  }
}