<div class="container">

  <div class="container-filter">
    <mat-form-field class="form-field container-filter-dp" appearance="outline">
      <mat-label>Mês/Ano</mat-label>
      <input
        matInput
        [matDatepicker]="dp"
        [formControl]="dateMoment"
        [(ngModel)]="dateSelected"
    
      />
      <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
      <mat-datepicker
        #dp
        startView="multi-year"
        (monthSelected)="setMonthAndYear($event, dp)"
        panelClass="example-month-picker"
      >
      </mat-datepicker>
    </mat-form-field>
    
    <button
    mat-stroked-button
    class="container-filter-btn"
  
    (click)="getDataFinance(dateSelected)"
    >
    <mat-icon>filter_alt</mat-icon> Filtrar
    </button>
  </div>

  <div class="container-card"  >
   
    <div>
      <img
      class="container-img"
      src="./../../../assets/png/Baixa inadimplência 1.png"
      alt=""
      />
      <section class="container-card-main">
        
        <h1 class="container-card-main-title">Total à pagar</h1>
        <div class="container-card-main-data">
          <span class="container-card-main-data-date"  *ngIf="data; else noDataLow"> {{dateSelected.locale('pt-br').format('MMMM/YYYY') | titlecase}}</span>
          <div class="container-card-main-data-item" >
            <span class="container-card-main-data-item-value"  *ngIf="data ; else noDataHigh">{{data.content[0].totalPayable.toFixed(2) | currency:'BRL' }}</span>
              <button class="container-card-main-data-item-btn" 
              (click)="redirectTo(dateSelected)"  
            >
              Extrato
            </button>
          </div>
        </div>
      </section>
      <section class="container-card-details">  
        <div class="container-card-details-item">
          <span  class="container-card-details-item-label">Comissão</span>
          <span class="container-card-details-item-value"  *ngIf="data; else noDataLow">{{data.content[0].totalCommissions.toFixed(2) | currency:'BRL'}} </span>
        </div>
        <div  class="container-card-details-item">
          <span class="container-card-details-item-label">Documentos Digitais </span>
          <span class="container-card-details-item-value" *ngIf="data; else noDataLow"> {{data.content[0].totalDocSign.toFixed(2) | currency:'BRL'}}</span>
        </div>
      </section>
    </div>
  </div>

 
</div>





<ng-template  #loading_medium >
  <ngx-skeleton-loader 
    count="1" 
    appearance="line" 
    animation="progress"  
    [theme]="{
      'background-color': '#595958',
      width:'90px',
      height:'20px',
      margin:'0'
    }"
  >
  </ngx-skeleton-loader>
</ng-template>


<ng-template  #noDataHigh >
  <span class="erro-high" *ngIf="(error$ | async) else loadingHigh">#####</span>
  <ng-template #loadingHigh >
    <ngx-skeleton-loader 
      count="1"
      appearance="line"
      animation="progress"
      [theme]="{
        'background-color': '#595958',
        width:'150px',
        height:'36.7px',
        margin: '3px 0 0 0'
      }"
    >
    </ngx-skeleton-loader>
  </ng-template>
</ng-template> 

<ng-template  #noDataLow >
  <span class="erro-low" *ngIf="(error$ | async) else loadingLow">######</span>
  <ng-template #loadingLow >
    <ngx-skeleton-loader 
    count="1" 
    appearance="line" 
    animation="progress"  
    [theme]="{
      'background-color': '#595958',
      width:'90px',
      height:'20px',
      margin:'0'
    }"
  ></ngx-skeleton-loader>
  </ng-template>
</ng-template> 