import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { OperationLead } from '../interfaces/operacaoFilters';
import { AnalysisOperationsResponseInterface } from '../interfaces/analysis-operations.interface';
import { OperationsComponent } from '../pages/operations/operations.component';
import { FiltersInterface } from '../interfaces/filters.interface';
import {
  BanksInterface,
  CompanyDataInterface,
  ContactCompany,
} from '../interfaces/companyData.interface';
import { UpdateInstallments } from '../interfaces/updateInstallments.interface';
import { DocumentsInterface } from '../interfaces/documents-status.interface';
import { ModalAditiveInterface } from '../interfaces/modal-aditive.interface';
import { LiquidatedPartinal } from '../interfaces/liquidated-partinal.interface';
import { ModalFeeInterface } from '../interfaces/modal-fee.interface';
import { CreditInfoInterface } from '../interfaces/creadit-limit.interface';
import { OperationDataCedente } from '../interfaces/operation-data.interface';
import { Lead } from '../interfaces/financial.interface';

@Injectable({
  providedIn: 'root',
})
export class buyerDashboardService {
  constructor(private http: HttpClient) {}

  getAll(
    pageIndex: number,
    pageSize: number,
    filters?: FiltersInterface
  ): Observable<{
    last: any;
    content: OperationLead[];
    totalElements: number;
  }> {
    const url =
      environment.operation_api + `open/installment/${pageIndex}/${pageSize}`;
    let { cnpj } = JSON.parse(window.sessionStorage.getItem('auth')!);
    const filterCnpj = filters?.companyList
      ? filters.companyList[0].cnpj
      : null;
    const companyList = [{ cnpj: filterCnpj, type: 'LEAD' }];

    if (filters?.status !== 'FOR_SALE') {
      companyList.push({ cnpj, type: 'BUYER' });
    }
    return this.http.post<{
      last: any;
      content: OperationLead[];
      totalElements: number;
    }>(url, {
      status: filters?.status || null,
      responsible: null,
      type: null,
      numberInvoice: null,
      createdAtInit: null,
      createdAtEnd: null,
      dueDateInit: null,
      dueDateEnd: null,
      ...filters,
      companyList,
    });
  }

  getSearchFomento(
    type: string,
    filter?: string
  ): Observable<{ content: any[] }> {
    const defaultType = 'LEAD';
    return this.http.get<{ content: any[] }>(
      environment.register_api +
        `secure-api/company/getall/${defaultType}/0/50/ContaAzul?filter=${
          filter || ''
        }`
    );
  }
  getCompanyData(cnpj: string): Observable<CompanyDataInterface> {
    const url =
      environment.register_api + `secure-api/company/getCompanyByCnpj/${cnpj}`;
    return this.http.get<CompanyDataInterface>(url);
  }
  updateInstallments(data: UpdateInstallments) {
    return this.http.put(environment.operation_api + 'open/installment', data);
  }

  getOperationsInAnalysis(
    filters?: FiltersInterface
  ): Observable<AnalysisOperationsResponseInterface> {
    const url = environment.operation_api + `open/installment/ANALYSIS/0/50`;
    const { cnpj } = JSON.parse(window.sessionStorage.getItem('auth')!);
    return this.http.post<AnalysisOperationsResponseInterface>(url, {
      status: null,
      responsible: null,
      type: null,
      numberInvoice: null,
      createdAtInit: null,
      createdAtEnd: null,
      dueDateInit: null,
      dueDateEnd: null,
      companyList: [
        {
          cnpj,
          type: 'LEAD',
        },
        {
          cnpj: null,
          type: null,
        },
      ],
    });
  }

  getDownloadFilesOperation(id: string): any {
    return this.http.get(
      environment.operation_api + `api/invoice/document/download/all/${id}`,
      {
        headers: {
          skip: 'true',
          Authorization: `Bearer ${environment.operation_api_token}`,
        },
        responseType: 'blob',
      }
    );
  }

  getSignedDocuments(type: 'company' | 'partner', id: string) {
    return this.http.get(
      environment.API_url + 'management-api/documents/leads?path=' + id,
      { responseType: 'text' }
    );
  }

  getDocumentOperation(): Observable<DocumentsInterface> {
    const url = environment.operation_api + `open/installment/DOCUMENT/0/50`;
    const { cnpj } = JSON.parse(window.sessionStorage.getItem('auth')!);

    return this.http.post<DocumentsInterface>(url, {
      status: null,
      type: null,
      numberInvoice: null,
      responsible: null,
      dueDateInit: null,
      dueDateEnd: null,
      createdAtInit: null,
      createdAtEnd: null,
      companyList: [
        {
          cnpj,
          type: 'BUYER',
        },
        {
          cnpj: null,
          type: null,
        },
      ],
    });
  }
  getModalAditive(additive: string): Observable<ModalAditiveInterface[]> {
    const url = environment.aditive_api + `collect/?additive=${additive}`;
    return this.http.post<ModalAditiveInterface[]>(url, []);
  }
  updatePartial(data: LiquidatedPartinal): Observable<any> {
    return this.http.put<any>(
      environment.operation_api + 'open/installment/settlement',
      data
    );
  }
  getEmoluments(data: string[]): Observable<ModalFeeInterface> {
    const url = environment.operation_api + 'open/installment/emoluments/';
    return this.http.post<ModalFeeInterface>(url, data);
  }

  getCreditInfo(companyId: string): Observable<CreditInfoInterface> {
    const url =
      environment.register_api +
      `secure-api/company/${companyId}/config/monthlyPlan`;
    return this.http.get<CreditInfoInterface>(url);
  }

  getBankInformation(id: string) {
    const url =
      environment.register_api + `secure-api/company/null/banks/${id}`;
    return this.http.get<BanksInterface>(url);
  }

  getOperationDataCedente(installmentId: string) {
    const buyerId = JSON.parse(window.sessionStorage.getItem('auth')!).auth;
    const url =
      environment.operation_api +
      `secure-api/operation_data/${buyerId}/${installmentId}/lead`;

    return this.http.get<OperationDataCedente>(url);
  }

  getContactCompany(id: string) {
    const url = environment.register_api + `secure-api/company/${id}/contact`;
    return this.http.get<ContactCompany[]>(url);
  }
}
